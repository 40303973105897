import { defineComponent } from "vue";
import EventBus from "@/EventBus";
export default defineComponent({
  name: "stam-header",
  data() {
    return {
      showMobileMenu:false
    }
  },
  computed: {
    falkenURL() {
      return process.env.VUE_APP_FALKEN_URL
    },
    kumhoURL() {
      return process.env.VUE_APP_KUMHO_URL
    }
  },
  methods: {
    goTO(refName: any) {
      if (this.$route.name == 'stamford-tyre-range') {
        const timer: ReturnType<typeof setTimeout> = setTimeout(function () {
            EventBus.emit("my-event", { eventContent: refName });
        }, 1200);
        this.$router.push({name: 'stamford-home'})
      } else {
        EventBus.emit("my-event", { eventContent: refName });
      }
    },
  },
});
