import { defineComponent } from 'vue';
import config from '@/config/app';
import EventBus from "@/EventBus";
export default defineComponent({
  name: 'our-history',
  data() {
    return {
      teamMember:config,
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    
    teamDetails(data : any) {
      var element : any
      var  commnElement : any
      commnElement =  document.querySelectorAll('.teamMember');
      if (commnElement) {
        commnElement.forEach((box: { classList: { remove: (arg0: string) => void; }; }) => {
          box.classList.remove('active');
        });
      }
      element = document.getElementById("teams"+data.id);
      element.classList.add("active");
      EventBus.emit("my-teams", { eventContent: data });
    },
    getImgUrl(pic : any) {
      var images = require.context('@/assets/images/stamford/teams/')
      return images('./' + pic)
    }
  },
  created() {
    // console.log('kkkk',config['managmentTeam'])
  },
});