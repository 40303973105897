<section class="st_our_history">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text mw-100 mb-4 pb-4 mb-xxl-5 pb-xxl-5">
          <h2 class="title" data-title="OUR PREMIUM BRANDS">
            OUR PREMIUM BRANDS
          </h2>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <a target="_blank" :href="falkenURL">
                <img
                  src="@/assets/images/stamford/logo/falken.webp"
                  alt="falken"
                />
              </a>
            </span>
            FALKEN (PCR/ SUV)
          </h3>
          <p>
            A favourite on the racetracks, Falken tyres have been developed and
            built to be one of the top motorsports performers. For drivers who
            expect high speed stability and good safety performance, and for
            those who want more grip and driving pleasure without sacrificing
            comfort, Falken tyres are the high-end tyres of choice. Taking high
            premium performance from racetrack to road.
          </p>
          <p>
            Falken is manufactured by Sumitomo Rubber Industries, a leading
            Japanese designer and manufacturer of tyres. The Falken range has
            tyres for high performance cars, passenger cars and SUVs. Falken
            continually introduces new and exciting products and as part of its
            R&D programme, Sumitomo supports many race and rally teams round the
            world, outfitting their competition machines with Falken Tyres.
          </p>
          <p>
            Stamford Tyres distributes Falken in Singapore, Malaysia, Thailand,
            Indonesia, Brunei, Vietnam, Cambodia, Myanmar, Laos, South Africa,
            Namibia, Botswana, Lesotho, Eswatini, Hong Kong, India, Bangladesh,
            Pakistan, Mauritius, Reunion, East Timor, Nepal and New Caledonia.
          </p>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <a target="_blank" :href="kumhoURL">
                <img
                  src="@/assets/images/stamford/logo/kumho.jpg"
                  alt="Kumho"
                />
              </a>
            </span>
            KUMHO (PCR/ SUV)
          </h3>
          <p>
            Kumho Tyre’s world-renowned quality is a result of a strong commitment to advanced design technology and drive to innovate. Excellent products that deliver optimum performance, quality, and most importantly, safety, are the Kumho Tyre promise. Having grown to the 7th largest company in Korea, Kumho Tyres runs three manufacturing facilities in South Korea. There are a further three plants in China as well as one plant in Vietnam.
          </p>
          <p>
            Kumho Tyres exports tires worldwide and has a global network of sales organisations including Stamford Tyres Africa (Pty) Ltd. Kumho Tyres has three centres for research and development, with the largest in Gwangju, South Korea. The other two centres are in Akron, Ohio and Birmingham, West Midlands. These service the US and European tire markets respectively. 
          </p>
          <p>
            Kumho Tyres has a global presence across 180 countries and produces more than 68 million tyres every year. Kumho defines itself through innovation and quality. Alongside supporting a whole host of Motorsport events, Kumho has also developed a tyre for F1. 
          </p>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <img
                src="@/assets/images/stamford/logo/tomket.webp"
                alt="tomket"
              />
            </span>
            TOMKET (PCR)
          </h3>
          <p>
            TOMKET was established in 1997, located in Czech 
            Republic, the middle of Europe We began and remain a family-owned business that has 
            significantly outperformed our dreams. From a bespoke online 
            retailer in Czech Republic to a global tyre brand. It has been an 
            amazing journey and one that will embed humbleness throughout 
            our business as we continue to grow.
          </p>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <img
                src="@/assets/images/stamford/logo/prometeon-pirelli.webp"
                alt="prometeon"
              />
            </span>
            PROMETEON Pirelli (TBR)
          </h3>
          <p>
            Prometeon Tyre Group manufactures and sells tires especially for industry, transport of goods and passengers, and AGRO and OTR applications.
            Officially established in 2017 as a spin-off from the industrial division of PIRELLI Tyre, Prometeon starts its path producing and selling PIRELLI branded tires.
            Nowadays, the company is progressively developing a multi-brand and multi-product approach, widening its technological expertise.
            Each tire is the result of #PrometeonTechnology engineering work focused on innovation with the aim of offering the right solution to our customers.

            <div class="row  row-gap-3">
              <div class="col-12 col-md-3">
                <b>Employees</b><br/>
                8000
              </div>
              <div class="col-12 col-md-3">
                <b>Researchers</b><br/>
                100
              </div>
              <div class="col-12 col-md-3">
                <b>Factories</b><br/>
                4
              </div>
              <div class="col-12 col-md-3">
                <b>R&D Centers</b><br/>
                4
              </div>
            </div>
          </p>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <img
                src="@/assets/images/stamford/logo/ascenso.webp"
                alt="ascenso"
              />
            </span>
            ASCENSO (Agric/Ind)
          </h3>
          <p>
            Ascenso is an off-highway tyre brand owned and promoted by Mahansaria Tyres Private Limited (MTPL). It is the third such
            venture by the renowned Mahansaria Family. In their journey of more than three decades, they have built two leading OHT
            tyre brands with global footprints.
          </p>

          <p>
            MTPL builds and benefits from the experience, knowledge, and successful track record of the father-son duo, Ashok
            Mahansaria and Yogesh Mahansaria, the promoters and owners of MTPL.
          </p>

          <p>
            MTPL has set up a greenfield off-highway tyre manufacturing plant in Panoli, Gujarat (India). The state-of-the-art
            facility, spread over 127 acres/ 55,30,000 Sq.Ft, has a manufacturingbcapacity of 70,000 MT built in two phases. The
            phase-1 capacity is 40,000 MT, and the phase-2 capacity is 30,000 MT.
          </p>

          <p>
            Ascenso, as a brand, caters to the needs of the following key global segments: Agriculture, Industrial & Construction,
            Earth Mover, Material Handling, and Agro-Forestry. In our journey over the last 3 years, we have released and added more
            than 500+ sizes to our portfolio. Ascenso is now distributed in over 85 plus countries. We not only offer a wide range
            of tyres across the applications but also customize solutions to suit specific customer requirements or applications.
            Our aim is to be present in all global markets, and every step forward brings us closer to our vision of becoming the
            most preferred brand in the Off-Highway Tyre Industry.
          </p>
        </div>
        <div class="text mw-100">
          <h2 class="title" data-title="PROPRIETARY BRANDS">
            PROPRIETARY BRANDS
          </h2>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <img
                src="@/assets/images/stamford/logo/firenza.webp"
                alt="SUMO FIRENZA"
              /> </span
            >SUMO FIRENZA (TBR/AGRIC/IND/OTR)
          </h3>
          <p>
            Sumo Firenza is a proprietary brand of tyres from Stamford Tyres International.  During the past decade, Sumo Firenza has evolved into a global brand known for its commitment to quality, continuous innovation and dependability.  Concentrating on constant technical and product advancement, the brand provides premium Truck and Bus Radial Tyres.
          </p>
          <p>
            Spearheaded by an experienced engineering team, the brand continues to expand its product portfolio as demanded by today’s discerning global market. 
          </p>
          <h3 class="sub_title">
            <span class="logo_wrap">
              <img
                src="@/assets/images/stamford/logo/ssw-perfomace-wheels.webp"
                alt="STAMFORD SPORT WHEELS"
              /> </span
            >STAMFORD SPORT WHEELS (SSW)
          </h3>
          <p>
            Stamford Sport Wheels, or SSW, is our proprietary brand of alloy
            wheels. Our wheels are designed, manufactured and tested in-house.
            Our designers have created a line of wheels that offer exciting
            style, looks and road performance. SSW has models with diameters of
            13” to 26” tailored for passenger cars and SUVs. Our wheels are
            produced in Thailand using the latest low pressure and tilt gravity
            casting methods and in accordance with internationally recognized
            quality and safety standards.
          </p>

          <h3 class="sub_title">Other Brands</h3>
          <ul class="logo_list">
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/annaite.webp"
                alt="Annaite"
              />
            </li> -->
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/blacklion.webp"
                alt="Blacklion"
              />
            </li> -->
            <li>
              <img
                src="@/assets/images/stamford/logo/firemax.webp"
                alt="Firemax"
              />
            </li>
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/firenza.webp"
                alt="FIRENZA"
              />
            </li> -->
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/fortune-tyres.webp"
                alt="Fortune Tyres"
              />
            </li> -->
            <li>
              <img
                src="@/assets/images/stamford/logo/hilo.webp"
                alt="hilo"
              />
            </li>
            <li>
              <img
                src="@/assets/images/stamford/logo/honour-tyres.webp"
                alt="Honour Tyres"
              />
            </li>
            <li>
              <img
                src="@/assets/images/stamford/logo/inning-tyres.webp"
                alt="Inning Tyres"
              />
            </li>
            <li>
              <img
                src="@/assets/images/stamford/logo/rockbuster-tyres.webp"
                alt="Rockbuster Tyres"
              />
            </li>
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/rovelo-tyres.webp"
                alt="Rovelo Tyres"
              />
            </li> -->
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/stamford-tyres.webp"
                alt="Stamford Tyres"
              />
            </li> -->
            <li>
              <img
                src="@/assets/images/stamford/logo/sumo-akina.webp"
                alt="Sumo Akina"
              />
            </li>
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/sumo-tire.webp"
                alt="Sumo Tire"
              />
            </li> -->
            <!-- <li>
              <img
                src="@/assets/images/stamford/logo/sunwide-tyres.webp"
                alt="Sunwide tyres"
              />
            </li> -->
            <li>
              <img
                src="@/assets/images/stamford/logo/top-trust-tyres.webp"
                alt="Top Trust Tyres"
              />
            </li>
            <li>
              <img
                src="@/assets/images/stamford/logo/transmate.webp"
                alt="Transmate"
              />
            </li>
            <li>
              <img
                src="@/assets/images/stamford/logo/troika.webp"
                alt="Troika"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
