import { defineComponent } from 'vue';
import EventBus from "@/EventBus";
export default defineComponent({
  name: 'sean forrester',
  data() {
    return {
      description:'José joined Stamford Tyres in July 2021, he has a proven background of directorship of sales and turning profits towards a favourable position for a company. His extensive experience in the tyre industry has afforded him deep understanding of the market place. Given Jose’s passion about developing and growing the business through mentorship, he is ideally positioned to lead the Stamford Tyres team towards enhancing the organisation’s image and exceeding overall growth objectives. José looks forward to continuing and building on customer relationships over the years to come.',
      designation:'General Manager',
      image:'jose-romero.webp',
      name:'José Romero'
    }
  },
  methods: {
    getImgUrl(pic : any) {
      var images = require.context('@/assets/images/stamford/team-details/')
      return images('./' + pic)
    }
  },
  mounted (){
    var element : any
    EventBus.on('my-teams', (evt : any) => {
      this.name = evt.eventContent.name;
      this.description = evt.eventContent.description;
      this.image = evt.eventContent.image;
      this.designation = evt.eventContent.designation;
      element = document.getElementById("teamDetails");
      var team = element.offsetTop;
      window.scrollTo(0, team);
    })
  }
});