import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e5ec5f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "st_sean_forrester grey_dark_bg white p-0",
  id: "teamDetails"
}
const _hoisted_2 = { class: "container-fluid p-0 wrapper" }
const _hoisted_3 = { class: "row g-0" }
const _hoisted_4 = { class: "col-lg-4 col-xxl-3" }
const _hoisted_5 = { class: "img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-lg-8 col-xxl-9" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "heading_title fw_700" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.getImgUrl(_ctx.image),
              alt: "SEAN FORRESTER",
              class: "img-fluid"
            }, null, 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.name), 1),
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.designation), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
          ])
        ])
      ])
    ])
  ]))
}